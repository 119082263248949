import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ItemTeams = () => {
  const [itemTeams, setItemTeams] = useState([]);

  useEffect(() => {
    const detailsItemTeams = [
  
    ];
    setItemTeams(detailsItemTeams);
  }, []);
  const {t}=useTranslation()
  return (
    <div className="wrapper_contain_item_teams">
      <div className="row">
        {itemTeams.map((itemTeam) => (
          <div className="col-md-4 col-sm-6" key={itemTeam.id}>
            <div className="item_teams trans">
              <div className="text-center photo_teams">
                <img src={itemTeam.photo} alt="" />
              </div>
              <div className="text-center">
                <h6 className="c_morado fw_400">{itemTeam.position}</h6>
                <h4 className="fw_600 mb-3">{itemTeam.name}</h4>
                <p className="paragraph sm">
                  {/* <a href={`mailto:${itemTeam.email}`}>
                    <span>
                      <i className="fa-regular fa-envelope"></i>
                    </span>
                    <span>{itemTeam.email}</span>
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="col-md-12">
          <Link to="/bolsa" className="circle_link">
            <div className="w-100">
              <h3 className="mb-0">{t('Bolsa de trabajo')}</h3>
              <p className="paragraph mb-0">{t('Únete de nuestro equipo')}</p>
            </div>
          </Link>
        </div>
        
      </div>
    </div>
  );
};